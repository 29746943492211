export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~2],
		"/actualites": [~3],
		"/actualites/[actualite_id]": [~4],
		"/landing/[landing_id]": [5],
		"/mentions_legales": [6],
		"/nos_metiers": [7],
		"/nos_metiers/bornes_recharge": [~8],
		"/nos_metiers/electricite": [~9],
		"/nos_metiers/photovoltaique": [~10],
		"/nous_contacter": [~11],
		"/qui_sommes_nous": [~12]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';